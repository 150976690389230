@use "@angular/material" as mat;

@include mat.core();

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

$sasmg-palette: (
  50: #ffffff,
  100: #f3f4f1,
  200: #e7eae3,
  300: #dbdfd6,
  400: #cfd5c8,
  500: #c3caba,
  600: #abb59e,
  700: #9faa91,
  800: #93a083,
  900: #879575,
  A100: #ef6c6c,
  A200: #ea3e3e,
  A400: #f50000,
  A700: #db0000,
  contrast: (
    50: #131313,
    100: #131313,
    200: #131313,
    300: #131313,
    400: #131313,
    500: #131313,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #131313,
    A200: #131313,
    A400: #131313,
    A700: #131313,
  ),
);

$light-theme: mat.define-light-theme(
  (
    color: (
      primary: mat.define-palette(mat.$blue-grey-palette),
      accent: mat.define-palette(mat.$blue-grey-palette),
    ),
    typography: mat.define-typography-config(),
    density: 0,
  )
);

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  @include mat.all-component-themes($light-theme);
}

html,
body {
  height: 100%;
}

.color-sort-header-arrow-white {
  .mat-sort-header-arrow {
    color: #fff;
  }
}
